import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import Navbar from "./Navbar";
import baseURL from "../config";
import {validateAdmin} from "./Beranda";
import {toast, ToastContainer} from "react-toastify";

const fetchStudentsData = async (institution, kelas, tahunAjaran, page) => {
    const normalizedInstitution = institution.replace(/\s+/g, '%20').toLowerCase();
    const normalizedKelas = kelas.replace(/\s+/g, '%20').toLowerCase();
    const normalizedTahunAjaran = tahunAjaran;
    const respond = await axios.get(`${baseURL}/${normalizedInstitution}/${normalizedKelas}/${normalizedTahunAjaran}/${page}`);
    return respond;
};

const fetchClasses = async () => {
    const response = await axios.get(`${baseURL}/all-kelaslembaga`);
    return response.data;
};

const Modal = ({ isOpen, onClose, student, onSave, daftarKelas }) => {
    const [nama, setNama] = useState('');
    const [rfid, setRfid] = useState('');
    const [selectedKelas, setSelectedKelas] = useState('');

    useEffect(() => {
        if (student) {
            setNama(student.santriDetail.nama_santri || '');
            setRfid(student.santriDetail.rfid || '');
            setSelectedKelas(student.kelas || '');
        }
    }, [student]);

    const handleSave = async () => {
        try {
            const response = await axios.patch(`${baseURL}/update-santri`, {
                rfid: rfid,
                nis: student.nis_santri,
                nama_santri: nama,

            });
            console.log('Server response:', response.data);


        } catch (error) {
            console.error('Error updating santri:', error);
        }

        try {

            const response2 = await axios.patch(`${baseURL}/update-kelas/${student.nis_santri}`, {
                kelas: selectedKelas
            });

            console.log('Server response2:', response2.data);

        } catch (error) {
            console.error('Error updating kelas santri:', error);
        }


        onSave();
        onClose();
    };



    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl w-96">
                <h2 className="text-xl font-bold mb-4">Edit Student</h2>
                <div className="mb-4">
                    <p><strong>NIS:</strong> {student.nis_santri}</p>
                    <p><strong>Pemilik:</strong> {student.pemilik}</p>
                    <p><strong>Tahun Ajaran:</strong> {student.tahun_ajaran}</p>
                    <p><strong>Gender:</strong> {student.santriDetail.gender}</p>
                    <div className="mb-2">
                        <label className="block text-sm font-bold mb-2">Kelas</label>
                        <select
                            value={selectedKelas}
                            onChange={(e) => setSelectedKelas(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            {daftarKelas.map((kelas) => (
                                <option key={kelas} value={kelas}>
                                    {kelas}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2">Nama Santri</label>
                    <input
                        type="text"
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2">RFID</label>
                    <input
                        type="text"
                        value={rfid}
                        onChange={(e) => setRfid(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSave}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    >
                        Simpan
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    );
};


const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg shadow-xl w-96">
                <h2 className="text-xl font-bold mb-4">Konfirmasi Hapus</h2>
                <p>Apakah anda yakin menghapus data ini?</p>
                <div className="flex justify-end mt-4">
                    <button
                        onClick={onConfirm}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    >
                        Ya
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Batalkan
                    </button>
                </div>
            </div>
        </div>
    );
};

export const DataSantriPages = () => {
    const [institution, setInstitution] = useState('semua instansi');
    const [daftarKelas, setDaftarKelas] = useState('semua kelas');
    const [tahunAjaran, setTahunAjaran] = useState('2023-2024');
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const { data: classData, status: classStatus } = useQuery('classes', fetchClasses);

    const { data, status, refetch } = useQuery(['students', institution, daftarKelas, tahunAjaran, currentPage], () =>
        fetchStudentsData(institution, daftarKelas, tahunAjaran, currentPage)
    );

    useEffect(() => {
        if (classStatus === 'success' && classData.length > 0) {
            const filteredClasses = classData.filter(c => c.pemilik.toLowerCase() === institution.toLowerCase());
            setDaftarKelas(filteredClasses.length > 0 ? filteredClasses[0].kelas : 'semua kelas');
        }
    }, [institution, classData, classStatus]);

    useEffect(() => {
        if ((institution.toLowerCase() === 'madin')||(institution.toLowerCase() === 'pondok')) {
            setTahunAjaran('1445-1446');
        } else {
            setTahunAjaran('2024-2025');
        }
    }, [institution]);

    const sortedData = useMemo(() => {
        if (data?.data) {
            return [...data.data].sort((a, b) => {
                if (a.pemilik < b.pemilik) return -1;
                if (a.pemilik > b.pemilik) return 1;
                if (a.kelas < b.kelas) return -1;
                if (a.kelas > b.kelas) return 1;
                return a.santriDetail.gender.localeCompare(b.santriDetail.gender);
            });
        }
        return [];
    }, [data]);

    const getClassOptions = () => {
        if (classStatus === 'loading') return ['Loading...'];
        if (classStatus === 'error') return ['Error loading classes'];

        let filteredClasses = classData.filter(c => c.pemilik.toLowerCase() === institution.toLowerCase());

        if (institution.toLowerCase() !== 'madin') {
            filteredClasses = filteredClasses.sort((a, b) => a.kelas.localeCompare(b.kelas));
        }

        return filteredClasses.map(c => c.kelas);
    };

    const generateTahunAjaranOptions = (Instansi) => {
        let options = [];
        if ((Instansi.toLowerCase() === 'madin')||(Instansi.toLowerCase() === 'pondok')) {
            for (let year = 1445; year <= 1460; year++) {
                options.push(`${year}-${year + 1}`);
            }
        } else {
            for (let year = 2024; year <= 2030; year++) {
                options.push(`${year}-${year + 1}`);
            }
        }
        return options;
    };

    const handleEditClick = (student) => {

        if (validateAdmin()){
            setSelectedStudent(student);
            setIsModalOpen(true);
        }else {
            toast.error(`Hanya Admin / Operator yang mengubah`, {autoClose: 3100,});
        }

    };

    const handleDeleteClick = (student) => {


        if (validateAdmin()){
            setSelectedStudent(student);
            setIsDeleteModalOpen(true);
        }else {
            toast.error(`Hanya Admin / Operator yang mengubah`, {autoClose: 3100,});
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedStudent(null);
    };

    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
        setSelectedStudent(null);
    };

    const handleSave = () => {
        refetch();
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${baseURL}/delete-santri-kelas`, {
                data: {
                    nis_santri: selectedStudent.nis_santri,
                    kelas: selectedStudent.kelas,
                    pemilik: selectedStudent.pemilik,
                    tahun_ajaran: selectedStudent.tahun_ajaran,
                },
            });
            console.log('Sukses!');
            refetch();
        } catch (error) {
            console.error('Error deleting student:', error);
        }
        setIsDeleteModalOpen(false);
        setSelectedStudent(null);
    };

    return (
        <>
            <Navbar />
            <ToastContainer/>
            <div className="sticky top-0 bg-white p-4 shadow">
                <div className="flex gap-4 mb-4">
                    <select onChange={e => setInstitution(e.target.value)} className="form-select px-1 py-3 border border-black shadow-lg bg-white font-bold">
                        {["Pilih Lembaga", "Pondok","Madin", "Ma", "Mts", "Sdi","DU"].map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                    <select value={daftarKelas} onChange={e => setDaftarKelas(e.target.value)} className="form-select px-2 py-3 border border-black shadow-lg bg-white font-bold">
                        {getClassOptions().map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                    <select value={tahunAjaran} onChange={e => setTahunAjaran(e.target.value)} className="form-select px-1 py-3 border border-black shadow-lg bg-white font-bold">
                        {generateTahunAjaranOptions(institution).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className="overflow-auto">
                    <table className="min-w-full divide-y divide-gray-500">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama Santri</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kelas</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">L/P</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tahun Ajaran</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pemilik</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">NIS Santri</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">RFID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">AKSI</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama Santri</th>

                        </tr>
                        </thead>
                        <tbody>
                        {status === 'loading' ? (
                            <tr><td colSpan="8">Loading...</td></tr>
                        ) : status === 'error' ? (
                            <tr><td colSpan="8">Error fetching data.</td></tr>
                        ) : (
                            sortedData.map((student, index) => (
                                <tr key={student.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-yellow-100'}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.santriDetail.nama_santri}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.kelas}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.santriDetail.gender}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.tahun_ajaran}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.pemilik}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{student.nis_santri}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{student.santriDetail.rfid}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-2">
                                        <button
                                            onClick={() => handleEditClick(student)}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDeleteClick(student)}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Hapus
                                        </button>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-black">{student.santriDetail.nama_santri}</td>

                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center mt-4">
                    <button className="mx-2 bg-blue-500 text-white font-bold  px-6 py-2 rounded shadow-lg shadow-blue-900" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Sebelumnya</button>
                    <button className="bg-blue-500 text-white font-bold  px-6 py-2 rounded shadow-lg shadow-blue-900" onClick={() => setCurrentPage(currentPage + 1)}>Selanjutnya</button>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                student={selectedStudent}
                onSave={handleSave}
                daftarKelas={getClassOptions()}
            />
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                onConfirm={handleDeleteConfirm}
            />
        </>
    );
};
